import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'parent',
        'child',
        'hiddenValueField',
    ];

    _getCurrentlySelectedChildOptionId() {
        if (this.hiddenValueFieldTarget.hasAttribute('value')) {
            return this.hiddenValueFieldTarget.value;
        }

        return null;
    }

    _disableBothDropdowns() {
        this.parentTarget.disabled = true;
        this.childTarget.disabled = true;
        this.parentTarget.tomselect.control_input.disabled = true;
        this.childTarget.tomselect.control_input.disabled = true;
    }

    _registerOnSubmitListener() {
        this.parentTarget.form.addEventListener('submit', () => {
            this._disableBothDropdowns();
            if (this.childTarget.value !== '') {
                this._setActualSubmitValueOnHiddenField(this.childTarget.value);
            }
        });
    }

    _findParentOptionIdForChild(childId) {
        const parentToChildMappingObject = this._getParentToChildMappingJson();

        for (const [parent, parentProps] of Object.entries(parentToChildMappingObject)) {
            if (parentProps.id === childId) {
                return parentProps.id;
            }

            if (parentProps.hasOwnProperty('options')) {
                for (const [id, childProps] of Object.entries(parentProps.options)) {
                    if (id === childId) {
                        return parentProps.id;
                    }
                }
            }
        }
    }

    _initializeDropdowns() {
        const parentToChildMappingObject = this._getParentToChildMappingJson();
        let currentlySelectedOptionId = this._getCurrentlySelectedChildOptionId();

        if (currentlySelectedOptionId === null) {
            return;
        }

        const parentForThisChildOptionId = this._findParentOptionIdForChild(currentlySelectedOptionId);
        for (const option of this.parentTarget.options) {
            if (option.value === parentForThisChildOptionId) {
                option.selected = true;
                this.changeParentValue();
            }
        }

        for (const option of this.childTarget.options) {
            if (option.value === currentlySelectedOptionId) {
                option.selected = true;
                // this.childTarget.tomselect.sync();
            }
        }
    }

    _handleChildTargetVisibility() {
        let parentDiv = this.childTarget.closest('.form__row');
        // now we know which form__row is dependant on another one
        parentDiv.classList.add('is--dependant');

        if (this.childTarget.hasAttribute('disabled')) {
            parentDiv.classList.add('is--disabled');
        } else {
            parentDiv.classList.remove('is--disabled');
        }
    }

    connect() {
        this._initializeDropdowns();
        this._registerOnSubmitListener();
        this._handleChildTargetVisibility();
    }

    changeParentValue() {
        const attributeOptionParentToChildMapping = this._getParentToChildMappingJson();
        const selectedParentDomObject = this._getParentDomObject();
        const currentlySelectedChildId = this._getCurrentlySelectedChildOptionId();

        for (const [parent, props] of Object.entries(attributeOptionParentToChildMapping)) {
            if (props.id === selectedParentDomObject.value) {
                this._clearChildOptions();
                let newOption = '';

                /**
                 * if multiple (child-)options exists, add them
                 */
                if (props.hasOwnProperty('options')) {
                    for (const optionId in props.options) {
                        newOption = this._generateDomOption(optionId, props.options[optionId].value);
                        this.childTarget.appendChild(newOption);

                        if (newOption.value === currentlySelectedChildId) {
                            newOption.selected = true;
                        }

                        const parentOnlyHasASingleOption = Object.keys(props.options).length === 1;
                        if (parentOnlyHasASingleOption) {
                            newOption.selected = true;
                            if (typeof this.childTarget.tomselect !== 'undefined') {
                                this.childTarget.tomselect.sync();
                            }
                        }
                    }
                } else {
                    const selectedParentName = selectedParentDomObject.innerHTML;
                    /**
                     * no child options exists, add parent option as the relevant option here, autoselect
                     */
                    newOption = this._generateDomOption(
                        props.id,
                        selectedParentName
                    );

                    if (newOption.value === currentlySelectedChildId) {
                        newOption.selected = true;
                    }

                    this.childTarget.appendChild(newOption);

                    if (this.childTarget.hasOwnProperty('tomselect')) {
                        this.childTarget.tomselect.addOption(newOption)
                        this.childTarget.tomselect.setValue(newOption.value);
                        this.childTarget.tomselect.sync();
                    }
                }

                this._enableChildDropdown();
                this._handleChildTargetVisibility();
                this._unsetValueOnHiddenField();

                return;
            }
        }

        this._unsetValueOnHiddenField();
    }

    _getParentDomObject() {
        /**
         * this is the selection from the first dropdown, you actually  select the ID and get shown a translated value
         * "01J4438MEW61KC0X01EEQ8XMVS": "Germany"
         * So selectedParent.value would be the ID/key here.
         */
        return this.parentTarget.options[this.parentTarget.selectedIndex];
    }

    _getParentToChildMappingJson() {
        this.childTarget.parentElement.classList.add('is--loading');

        setTimeout(() => {
            this.childTarget.parentElement.classList.remove('is--loading');
        }, 750);

        try {
            /**
             * we have the mapping inside HTML, all attribute options with their child-options (coming from levels) and
             * their translations, we search for it here...
             * ...and once we found it, we may:
             * - search for options for the selected parent (id) inside your data json
             * - clear all existing options inside our child select (maybe we had something selected before)
             * - generate html tag <option> for each child-option we may have found
             */
            return JSON.parse(this.parentTarget.getAttribute('data-attribute-option-mapping'));
        } catch (error) {
            console.error('Error parsing attribute option mapping JSON:', error);
            return null;
        }
    }

    _generateDomOption(id, displayValue) {
        let opt = document.createElement('option');
        opt.value = id;
        opt.innerHTML = displayValue;

        return opt;
    }

    _clearChildOptions() {
        // clear options in child dropdown
        for (const oldOption in this.childTarget.options) {
            this.childTarget.remove(oldOption);
        }

        if (this.childTarget.hasOwnProperty('tomselect')) {
            this.childTarget.tomselect.sync();
        }
    }

    _enableChildDropdown() {
        this.childTarget.removeAttribute('disabled');
    }

    _unsetValueOnHiddenField() {
        this.hiddenValueFieldTarget.value = '';
    }

    _setActualSubmitValueOnHiddenField(value) {
        this.hiddenValueFieldTarget.value = value;
    }
}