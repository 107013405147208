import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
     static targets = [
          "canvas", // The canvas element
          "hiddenFileInputForCommunityImage", // The file input for the final composite image
          "hiddenFileInputForCommunityForegroundImage", // The file input for the plain image
     ];

     static values = {
          backgroundColor: String,
          foregroundImageSrc: String, // The source for an uploaded or chosen image
          iconImageSrc: String, // The source when the user chooses an icon
          useForegroundImage: Boolean, // Check whether to use foreground or icon
     };

     connect() {
          const ctx = this.canvasTarget.getContext('2d');
          const {width, height} = this.canvasTarget;

          // Set the background color in case there is no foreGroundImage
          if(this.foregroundImageSrcValue === '' && this.backgroundColorValue !== '') {
               ctx.fillStyle = this.backgroundColorValue || "#ffffff"; // Default to white if no color
               ctx.fillRect(0, 0, width, height);
          }

          const image = new Image();
          // check which image source we should use. (already uploaded image vs. clicked icon)
          image.src = this.useForegroundImageValue ? this.foregroundImageSrcValue : this.iconImageSrcValue;

          image.onload = () => {
               // Step 1: Draw the image only (no background)
               ctx.clearRect(0, 0, width, height); // Clear the canvas
               ctx.drawImage(image, 0, 0, width, height); // position left top (0, 0) and size (340x340)

               // Step 2: Save the foreground image (plain image without background)
               this.saveCanvasToFileInput(this.canvasTarget, 'community-foreground-image.png', this.hiddenFileInputForCommunityForegroundImageTarget);

               // Step 3: Add background color
               if (this.backgroundColorValue !== '') {
                    ctx.fillStyle = this.backgroundColorValue || "#ffffff"; // Default to white if no color
                    ctx.fillRect(0, 0, width, height);
               }

               // Step 4: Re-draw the image on top of the background
               ctx.drawImage(image, 0, 0, width, height);

               // Step 5: Save the final composite (background + image)
               this.saveCanvasToFileInput(this.canvasTarget, 'community-image.png', this.hiddenFileInputForCommunityImageTarget);
          };

          // set the clicked image button active
          if(this.iconImageSrcValue !== '') {
               // find the position of the last slash
               const lastSlashIndex = this.iconImageSrcValue.lastIndexOf("/");

               // find the position of the second-last slash to include "light" or "dark" icon theme
               const secondLastSlashIndex = this.iconImageSrcValue.lastIndexOf("/", lastSlashIndex - 1);

               // get the image path (e.g. "/light/10.png" or "/dark/9.png")
               const iconImagePath = this.iconImageSrcValue.slice(secondLastSlashIndex);

               // query all icon buttons
               let iconButtons = document.querySelectorAll('[data-model="iconImageSrc"]');

               // find the image path (e.g. /light/10.png) with searching them to the data-value
               iconButtons.forEach(button => {
                    if(button.dataset.value.includes(iconImagePath)) {
                         button.classList.add("is--active");
                    }
                    else {
                         button.classList.remove("is--active");
                    }
               });
          }
     }

     // Save the content of a canvas to a file input
     saveCanvasToFileInput(canvas, fileName, fileInputTarget) {
          canvas.toBlob((blob) => {
               if (blob) {
                    const file = new File([blob], fileName, {type: "image/png"});

                    // Use DataTransfer to update the file input
                    const dataTransfer = new DataTransfer();
                    dataTransfer.items.add(file);
                    fileInputTarget.files = dataTransfer.files;
                    fileInputTarget.dispatchEvent(new Event('change', {bubbles: true}));
               }
          }, 'image/png');
     }
}
