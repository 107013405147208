import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        const headerHeight = document.querySelector('header').offsetHeight;
        const rootMarginValue = '-' + (headerHeight + 1) + 'px' + ' 0px 0px 0px';

        // Create the IntersectionObserver instance
        this.observer = new IntersectionObserver(this.handleIntersection.bind(this), {
            root: null,
            threshold: 1,
            rootMargin: rootMarginValue, // as soon as the box hits the sticky header
        });

        // Observe the element this controller is attached to (this.element)
        this.observer.observe(this.element);
    }

    // Add or remove the sticky class depending on the position of the image preview
    handleIntersection(entries) {
        entries.forEach(entry => {
            if(entry.isIntersecting === false && entry.intersectionRect.y <= 100) {
                this.element.classList.add('is--sticky');
            }
            else {
                this.element.classList.remove('is--sticky');
            }
        });
    }

    // Stop observing when the controller is disconnected
    disconnect() {
        if (this.observer) {
            this.observer.disconnect();
        }
    }
}
