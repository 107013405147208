import { Controller } from '@hotwired/stimulus';
import { getComponent } from '@symfony/ux-live-component';

export default class extends Controller {
    static targets = [
        "form", // the <form> that is submitted
        "card", // the <div> that gets the css classes for animation
        "submitButton" // the <button type="submit">
    ];

    animationDuration = 500;

    // Get the live component, so we can run actions like 'saveAndContinue'
    async initialize() {
        this.component = await getComponent(this.element);
    }

    connect() {
        // this.bounceBackIn = this.bounceBackIn.bind(this);
        // window.addEventListener('setup-transition#bounceBackIn', this.bounceBackIn);
        window.addEventListener('setup-transition#bounceBackIn', (event) => this.bounceBackIn(event));
        window.addEventListener('setup-transition#handleForward', (event) => this.handleForward(event));

        document.addEventListener("turbo:render", (event) => {
            // on turbo-render we find out from which direction we will enter the card.
            const animationClass = this.getAnimationDirection();
            this.cardTarget.classList.add(animationClass);

            // remove all animation classes, so a new animation can start
            setTimeout(() => {
                this.removeAnimationClasses();
            }, this.animationDuration);
        }, {once: true});

        this.addHtmlDataSteps();
    }

    addHtmlDataSteps() {
        const htmlTag = document.documentElement;
        const fromStep = htmlTag.hasAttribute("data-from-step");
        const toStep = htmlTag.hasAttribute("data-to-step");

        if(!fromStep || !toStep) {
            htmlTag.setAttribute('data-from-step', '0');
            htmlTag.setAttribute('data-to-step', '0');
            //this.cardTarget.classList.add("enter-from-center");

            setTimeout(() => {
                this.removeAnimationClasses();
            }, this.animationDuration);
        }
    }

    // When the user clicks "Save and continue" we first slide out the current question and then manually call
    // the 'saveAndContinue' action from the live component
    handleSubmit(event) {
        event.preventDefault();

        // run animation
        this.forwardAnimation().then(() => {
            // use the form validation api (checkValidity) to check if the form meets all constraints
            if(this.formTarget.checkValidity() === true) {
                // update the data-attributes on the html element, so we now where to animate from on the next page
                document.documentElement.dataset.fromStep = this.getCurrentStep();
                document.documentElement.dataset.toStep = this.getCurrentStep() + 1;
            }
            else {
                // not valid, show a different bounce-in animation
                this.cardTarget.classList.add("bounce-in-left");
                setTimeout(() => {
                    this.removeAnimationClasses();
                }, this.animationDuration);
            }

            // programmatically submit the form
            this.component.action('saveAndContinue');
        });
    }

     // When the user clicks "Save and continue" on the image page we first slide out the current question and then manually open the next page
    handleForward(event) {
        // run animation
        this.forwardAnimation().then(() => {
            document.documentElement.dataset.fromStep = this.getCurrentStep();
            document.documentElement.dataset.toStep = this.getCurrentStep() + 1;
            Turbo.visit(event.detail.targetUrl);
        });
    }

    // In case of an error, we dispatch this function from php to show the card with error state again
    bounceBackIn() {
        this.removeAnimationClasses();
        this.cardTarget.classList.add("bounce-in-left");

        setTimeout(() => {
            this.removeAnimationClasses();
        }, this.animationDuration);
    }

    // When the user clicks "Go back" we update the html step data attributes and then visit the url
    handleBack(event) {
        event.preventDefault();
        const link = event.currentTarget; // get the element to which the event handler is attached
        const url = link.getAttribute('href'); // get the url form the href attribute

        const navigateToStep = this.getCurrentStep() - 1;

        // update the data-attributes on the html element, so we now where to animate from on the next page
        document.documentElement.dataset.fromStep = this.getCurrentStep();
        document.documentElement.dataset.toStep = navigateToStep.toString();

        // run the animation and then visit the url
        this.backAnimation().then(() => {
            Turbo.visit(url); // use instead of window.location.href = url, so the page is not rendered new and the data-attributes on html tag are kept
        });
    }

    // Simple forward animation (e.g. used on 'Save and continue')
    forwardAnimation() {
        return new Promise((resolve) => {
            this.cardTarget.classList.add("leave-to-left");

            setTimeout(() => {
                resolve(); // resolve promise to proceed
            }, this.animationDuration); // duration of your animation
        });
    }

    // Simple back animation (e.g. used on 'Go back' link)
    backAnimation() {
        return new Promise((resolve) => {
            this.cardTarget.classList.add("leave-to-right");

            setTimeout(() => {
                resolve(); // resolve promise to proceed
            }, this.animationDuration); // duration of the animation
        });
    }

    // Remove all animation classes
    removeAnimationClasses() {
        // can be found in _setup-community.scss
        this.cardTarget.classList.remove(
            "enter-from-left",
            "enter-from-right",
            "enter-from-center",
            "leave-to-left",
            "leave-to-right",
            "bounce-in-left",
        );
    }

    // Find out if the user moves to the left or right direction
    getAnimationDirection() {
        const fromStep = document.documentElement.dataset.fromStep;
        const toStep = document.documentElement.dataset.toStep;

        // if(fromStep === toStep) {
        //     return "enter-from-center";
        // }

        //direction === "left" ? "enter-from-right" : "enter-from-left"
        return toStep > fromStep ? "enter-from-right" : "enter-from-left";
    }

    // Find the value of the current step
    getCurrentStep() {
        // Retrieve the current step index from the URL or data attribute
        return parseInt(this.element.dataset.currentStep) || 0;
    }
}
