import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        "link" // the <a> tag
    ];

    animationDuration = 500;

    // Depending on which link the user clicked we set the html step data attributes and visit the clicked url
    handleNavigation(event) {
        event.preventDefault();
        const link = event.currentTarget; // get the element to which the event handler is attached
        const url = link.getAttribute('href'); // get the url form the href attribute
        document.documentElement.dataset.toStep = link.dataset.currentStep;

        this.linkTargets.forEach(link => {
           if(link.hasAttribute("aria-current")) {
               document.documentElement.dataset.fromStep = link.dataset.currentStep;
           }
        });

        // run the animation and then visit the url
        this.navigationAnimation().then(() => {
            Turbo.visit(url); // use instead of window.location.href = url, so the page is not rendered new and the data-attributes on html tag are kept
        });
    }

    // Forward or back navigation
    navigationAnimation() {
        return new Promise((resolve) => {
            const card = document.querySelector('[data-setup-transition-target="card"]');

            const fromStep = document.documentElement.dataset.fromStep;
            const toStep = document.documentElement.dataset.toStep;
            const direction = toStep > fromStep ? "left" : "right";
            card.classList.add(direction === "left" ? "leave-to-left" : "leave-to-right");

            setTimeout(() => {
                resolve(); // resolve promise to proceed
            }, this.animationDuration); // duration of the animation
        });
    }
}
