import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        "card", // The card wrapper on which the class should be toggled
        "openButton", // The button which opens the details
        "closeButton", // The button which closes the edit mode
        "body", // The card body
        "bodyEdit", // The card body when editing
        "collapseButtons", // the collapse buttons (like 'edit')
    ];

    static values = {
        hideBody: Boolean,
        isOpen: Boolean
    }

    connect() {
        // register custom Event listener to close all cards before taking turbo snapshot
        document.addEventListener('card:close-all', (e) => this.closeAllBeforeTurboSnapshot(e));
        document.addEventListener('card:close', (e) => this.close(e));
        document.addEventListener('card:show-success', (e) => this.showSuccess(e));

        // handle body & bodyEdit visibility
        if(this.isOpenValue) {
            this.bodyEditTarget.classList.add('is--visible');

            if(this.hasCollapseButtonsTarget) {
                this.collapseButtonsTarget.classList.add('is--hidden');
            }

            // hide the default body in case of error
            if(this.hideBodyValue) {
                this.bodyTarget.classList.add('is--hidden');
            }
        }
    }

    closeAllBeforeTurboSnapshot(e) {
        this.cardTargets.forEach(() => {
            this.close();
        });
    }

    open() {
        if(this.hasCollapseButtonsTarget) {
            this.collapseButtonsTarget.classList.add('is--hidden'); // hide the collapse buttons
        }

        if(this.hideBodyValue) {
            this.bodyTarget.classList.add('is--hidden');
        }

        this.bodyEditTarget.classList.add('is--visible'); // show the edit body
        this.bodyEditTarget.focus(); // focus the form wrapper div when opening the edit mode.
    }

    close(e) {
        if (e.detail.targetId && e.detail.targetId !== this.element.id) {
            return;
        }

        if (this.hasCollapseButtonsTarget) {
            this.collapseButtonsTarget.classList.remove('is--hidden'); // show the collapse buttons
        }

        this.bodyEditTarget.classList.remove('is--visible'); // hide the edit body
        this.bodyTarget.classList.remove('is--hidden'); // show the default body

        if (this.hasOpenButtonTarget) {
            this.openButtonTarget.focus(); // focus the 'open' button after closing the collapse mode
        }
    }

    showSuccess(e) {
        if (e.detail.targetId !== this.element.id) {
            return;
        }

        this.cardTarget.classList.add('is--success');

        setTimeout(() => {
            this.cardTarget.classList.remove('is--success');
        }, 2500); // make sure this timeout is the same as .card__saved animation duration
    }
}
